<template>
  <router-view />
</template>

<script>
import "@/assets/js/promotion/common";

export default {
  name: "PromotionLayout",
};
</script>

<style scoped>
@import "~@/assets/fonts/gmarketSans.css";
@import "~@/assets/css/promotion/normalize.css";
@import "~@/assets/css/promotion/common.css";
@import "~@/assets/css/promotion/content.css";
</style>
